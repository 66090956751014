import {
  CSSProperties, ForwardedRef, forwardRef, useRef,
} from 'react';

import Text from './text';
import Image from './image';

interface Props {
  title: {
    text: string;
    style: CSSProperties;
  }
  subtitle: {
    text: string;
    style: CSSProperties;
  }
  image: {
    src: string;
    style: CSSProperties;
  }
  style: CSSProperties;
  slideDuration: number;
}

const Card = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const imageRef = useRef<any>(null);

  return (
    <div
      ref={ref}
      style={props.style}
    >
      <div style={{ overflow: 'hidden', width: '100%', height: '100%' }}>
        <Text
          text={props.title.text}
          style={{
					  ...props.title.style,
          }}
        />
        <Text
          text={props.subtitle.text}
          style={{
					  ...props.subtitle.style,
          }}
        />
        <Image
          ref={imageRef}
          slideDuration={props.slideDuration}
          src={props.image.src}
          style={props.image.style}
        />
      </div>
    </div>
  );
});

Card.displayName = 'Card';

export default Card;
