import AudioPlayer from '../../../remotion/AudioPlayer';

function Audio({ audioData }: { audioData: any }) {
  return (
    <div
      style={{
			  aspectRatio: 1,
			  background: 'black',
      }}
      className="w-[324px] xsm:w-full"
    >
      <AudioPlayer data={audioData} />
    </div>
  );
}

export default Audio;
