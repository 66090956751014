import React, { useEffect, useState } from 'react';
import { Spinner } from '@nextui-org/react';

import LicaPage from '../../../components/LicaPage';
import Content from './content';

function PublicContent() {
  const [contentData, setContentData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/v1/wall/genai-hack/`,
    ).then(
      (response) => response.json(),
    ).then((response) => {
      setContentData(response.data);
      setIsLoading(false);
    });
  }, []); // Empty dependency array ensures this runs only once on mount

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner color="current" />
      </div>
    );
  }

  const validContentIds = [
    'bC9tB9n0j8NYnhFPIoG8', 'djiaUSoEMdARFMkMzuoH', 'vI6TJUgtDv51C73vX0oP',
    'bKkzgwDCshfMIGFighsb', 'KCRiqDlKUyMlZZGeXXwP', 'xkVQu3Q7q62kAR0xPfs1',
    'PNjNdwBYdhqzteH7A7zs', 'eWvd39T5ep0hBykrSDF8', 'AC5eE24NS13CPXeM2zlv',
    'xtdEYo7p7eJhzjSUUyzp', 'USZJMt6Kp0CHVxlZaqet', 'eg1Ymgn3sDzh67Y5J2uJ',
  ];

  return (
    <div
      className="max-w-[1200px] grid grid-cols-2 sm:grid-cols-1 min-md:grid-cols-3 min-lg:grid-cols-4 gap-4 min-md:gap-5"
    >
      {contentData.contents?.filter((item: any) => validContentIds.includes(item.content_id)).map((item: any, index: number) => (
        <Content key={index} item={item} />
      ))}
    </div>

  );
}

export default function HackathonWall() {
  return (
    <LicaPage>
      <div className="flex flex-col items-center mt-16">
        <h1 className="text-4xl font-bold mb-5">Gen AI Hackathon</h1>
        <div className="px-5 py-10 flex flex-col gap-7">
          <PublicContent />
        </div>
      </div>
    </LicaPage>
  );
}
