import React from 'react';
import { Button } from '@nextui-org/react';
import gsap from 'gsap';

export default function ShareButton(props: any) {
  async function onClick() {
    const currentUrl = window.location.href;
    const urlObj = new URL(currentUrl);
    urlObj.search = '';

    if (typeof navigator.share === 'function') {
      await navigator.share({
        title: 'Lica World',
        text: 'Check out what I created on Lica!',
        url: props.url || urlObj.toString(),
      });
    } else {
      gsap.set('.clipboard-message', {
        opacity: 1,
      });
      gsap.to('.clipboard-message', {
        opacity: 0,
        delay: 1,
      });
      await navigator.clipboard.writeText(props.url || urlObj.toString());
    }
  }

  if (props.isIconOnly) {
    return (

      <div className="relative">

        <Button
          onClick={onClick}
          color="primary"
          className="bg-tertiary-light dark:bg-primary-dark text-white"
          isIconOnly
          style={{
            padding: '0',
          }}
        >
          <img className="invert" src="/icons/share-icon.svg" alt="" />
        </Button>
        <div
          className="absolute font-medium text-xs clipboard-message opacity-0 bg-tertiary dark:text-primary-dark text-primary-light top-12 -left-5 px-2 text-nowrap py-1 rounded-full"
        >
          Copied
          to clipboard
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <Button
        color="primary"
        className="dark:text-primary-dark bg-tertiary-light text-primary-light rounded-xl dark:bg-secondary-dark text-sm font-medium z-10 flex items-center justify-center sm:w-12 w-24 h-10"
        onClick={onClick}
      >
        <img className="invert dark:invert-0" src="/icons/share-icon.svg" />
        <span className="sm:hidden">

          Share
        </span>
      </Button>
      <div className="absolute font-medium text-xs clipboard-message opacity-0 bg-tertiary dark:text-primary-dark text-primary-light top-12 -left-5 px-2 text-nowrap py-1 rounded-full">Copied to clipboard</div>
    </div>
  );
}
