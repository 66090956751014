import { continueRender, delayRender, staticFile } from 'remotion';

import { loadFont as loadFont1 } from '@remotion/google-fonts/Poppins';
import { loadFont as loadFont2 } from '@remotion/google-fonts/Roboto';
import { loadFont as loadFont3 } from '@remotion/google-fonts/Lato';
import { loadFont as loadFont4 } from '@remotion/google-fonts/Montserrat';
import { loadFont as loadFont5 } from '@remotion/google-fonts/Merriweather';
import { loadFont as loadFont6 } from '@remotion/google-fonts/SourceSans3';
import { loadFont as loadFont7 } from '@remotion/google-fonts/PlayfairDisplay';
import { loadFont as loadFont8 } from '@remotion/google-fonts/CormorantGaramond';
import { loadFont as loadFont9 } from '@remotion/google-fonts/Lexend';
import { loadFont as loadFont10 } from '@remotion/google-fonts/Abel';
import { loadFont as loadFont11 } from '@remotion/google-fonts/ArchivoNarrow';
import { loadFont as loadFont12 } from '@remotion/google-fonts/Oswald';
import { loadFont as loadFont13 } from '@remotion/google-fonts/Almarai';
import { loadFont as loadFont14 } from '@remotion/google-fonts/Bellefair';
import { loadFont as loadFont15 } from '@remotion/google-fonts/BodoniModa';
import { loadFont as loadFont16 } from '@remotion/google-fonts/Cinzel';
import { loadFont as loadFont17 } from '@remotion/google-fonts/GildaDisplay';
import { loadFont as loadFont18 } from '@remotion/google-fonts/Lora';
import { loadFont as loadFont19 } from '@remotion/google-fonts/Marcellus';
import { loadFont as loadFont20 } from '@remotion/google-fonts/Raleway';
import { loadFont as loadFont21 } from '@remotion/google-fonts/GowunBatang';
import { loadFont as loadFont22 } from '@remotion/google-fonts/AbrilFatface';
import { loadFont as loadFont23 } from '@remotion/google-fonts/Cardo';

const loadHaffer = () => {
  const waitForFont = delayRender();

  const hafferFont = new FontFace('Haffer', `url('${staticFile('fonts/HafferVF.ttf')}') format('truetype')`);
  hafferFont.load().then(() => {
    document.fonts.add(hafferFont);
    continueRender(waitForFont);
  }).catch((err) => console.log('Error loading font', err));
};

const fontLoaderRecord: Record<string, () => void> = {
  Haffer: loadHaffer,
  Poppins: loadFont1,
  Roboto: loadFont2,
  Lato: loadFont3,
  Montserrat: loadFont4,
  Merriweather: loadFont5,
  'Source Sans 3': loadFont6,
  'Playfair Display': loadFont7,
  'Cormorant Garamond': loadFont8,
  Lexend: loadFont9,
  Abel: loadFont10,
  'Archivo Narrow': loadFont11,
  Oswald: loadFont12,
  Almarai: loadFont13,
  Bellefair: loadFont14,
  'Bodoni Moda': loadFont15,
  Cinzel: loadFont16,
  'Gilda Display': loadFont17,
  Lora: loadFont18,
  Marcellus: loadFont19,
  Raleway: loadFont20,
  'Gowun Batang': loadFont21,
  'Abril Fatface': loadFont22,
  Cardo: loadFont23,
};

export const loadFonts = (fonts: string[]) => {
  fonts.forEach((font) => {
    if (fontLoaderRecord[font]) {
      fontLoaderRecord[font]();
    }
  });
};
