import { useState, useEffect } from 'react';

import useFetch from '@/hooks/useFetch';
import ChatBox from '@/pages/v3/edit-content/components/chatbox';

export default function Chat({
  regenerate, setIsProcessing, isProcessing, responseData, videoId, chatMessage,
}: {
  regenerate: (value: boolean) => void;
  setIsProcessing: (val: boolean) => void;
  isProcessing: boolean;
  responseData: any;
  videoId: string;
  chatMessage: string;
}) {
  const [messages, setMessages] = useState<any>([
    {
      id: 1,
      image: '',
      receiver: 'Lica',
      text: `Hello, ${responseData.user_info?.name}. Let me know how you want the video to be edited, and I will make those edits for you.`,
    },
  ]);

  const { fetchData: updateVideo } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/update`,
    { method: 'POST' },
  );

  const handleVideoUpdate = async (prompt: string) => {
    if (isProcessing) {
      return;
    }

    setIsProcessing(true);

    const payload = {
      prompt,
    };

    await updateVideo({
      body: JSON.stringify(payload),
    });

    regenerate(true);
  };

  const handleSend = async (inputValue: string) => {
    if (inputValue.trim() !== '') {
      setMessages((currentMessages: any) => ([
        ...currentMessages,
        {
          id: currentMessages.length + 1,
          image: '',
          sender: responseData.user_info?.name || 'User',
          text: inputValue,
        },
      ]));
      await handleVideoUpdate(inputValue);
    }
  };

  useEffect(() => {
    if (chatMessage) {
      setMessages((currentMessages: any) => ([
        ...currentMessages,
        {
          id: currentMessages.length + 1,
          image: '',
          receiver: 'Lica',
          text: chatMessage,
        },
      ]));
    }
  }, [chatMessage]);

  return (
    <div className="min-lg:ml-8 mb-4 min-lg:mb-0 h-full">
      <ChatBox
        messages={messages}
        handleSend={handleSend}
        isProcessing={isProcessing}
      />
    </div>
  );
}
