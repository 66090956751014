import React, { useEffect, useState } from 'react';
import { Spinner } from '@nextui-org/react';

import LicaPage from '@/components/LicaPage';
import useFetch from '@/hooks/useFetch';

import Favorites from './favorites';
import Content from './content';

function VideosContent() {
  const [contentData, setContentData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeGlowId, setActiveGlowId] = useState<string | null>(null);

  const { data: getData, triggerFetch: triggerGetFetch } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/`,
    { method: 'GET' },
  );

  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentData(getData.data);
    setIsLoading(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  if (isLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spinner color="current" />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 min-md:grid-cols-1 min-lg:grid-cols-2 xl:!grid-cols-3 w-full gap-4 min-md:gap-6">
      {contentData.contents?.length === 0
        ? (
          <div className="text-center">You don't have any generated videos yet.</div>
        )
        : contentData.contents?.map((item: any, index: number) => (
          <Content
            key={index}
            item={{ ...item, index }}
            activeGlowId={activeGlowId}
            setActiveGlowId={setActiveGlowId}
          />
        ))}
    </div>
  );
}

export default function MyVideos() {
  return (
    <LicaPage>
      <div className="w-fit px-5 pt-10 pb-20 flex flex-col gap-10 items-center m-auto">
        <h2 className="text-3xl font-medium">My Videos</h2>
        <VideosContent />
      </div>
      {/* <div className="w-fit px-5 pt-10 pb-20 flex flex-col gap-10 items-center m-auto"> */}
      {/*  <h2 className="text-3xl font-medium">Lica's Favorites</h2> */}
      {/*  <div */}
      {/*    className="grid grid-cols-1 min-md:grid-cols-1 min-lg:grid-cols-2 xl:!grid-cols-3 w-full gap-4 min-md:gap-6" */}
      {/*  > */}
      {/*    <Favorites /> */}
      {/*  </div> */}
      {/* </div> */}
    </LicaPage>
  );
}
