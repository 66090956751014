import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import { downloadContent } from '@/services/content';

function Video({
  contentId, videoData, responseData, publicEdit = false, customNumber,
}: {
  contentId: string;
  videoData: any;
  responseData: any;
  publicEdit?: boolean;
  customNumber?: number;
}) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadClick = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    await downloadContent(videoData);
    setIsDownloading(false);
  };

  const handleEditClick = async () => {
    navigate(`/content/${contentId}/edit`);
  };

  return (
    <div className="flex-auto flex flex-col items-center w-full">
      <div className="flex flex-col flex-auto items-center h-full w-full">
        <div
          style={{
            aspectRatio: videoData.width / videoData.height,
          }}
          className="rounded-xl flex-auto h-full max-w-full max-h-full"
        >
          {videoData.download_links ? (
            responseData.user_info.email === authContext.currentUser?.email ? (
              <RemotionPlayer
                data={videoData}
                clickToPlay={false}
              />
            ) : (
              <div className=" flex items-center justify-center">
                <video src={videoData.download_links.media} controls />
              </div>
            )
          ) : (
            <RemotionPlayer
              data={videoData}
              clickToPlay={false}
            />
          )}
        </div>
      </div>
      <Spacer y={6} />
      <div className="flex items-center justify-center gap-4">
        <Button
          radius="full"
          size="md"
          color="primary"
          className="text-white"
          onClick={handleDownloadClick}
          isLoading={isDownloading}
        >
          Download video
        </Button>
        {publicEdit
          || responseData.user_info.email === authContext.currentUser?.email ? (
          <Button
            radius="full"
            size="md"
            style={{
              background: 'var(--BG-6, #E0E7FF)',
              color: 'var(--Primary-Color, #5046E5)',
            }}
            onClick={handleEditClick}
            startContent={<Icon icon="iconamoon:edit" />}
          >
            Edit
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default Video;
