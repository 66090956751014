// @ts-nocheck

import { useRef, useState } from 'react';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import { Editor } from '@tinymce/tinymce-react';
import dayjs from 'dayjs';

import useFetch from '../../../../hooks/useFetch';

interface EditBlogProps {
  title: string;
  data: any;
  blogId: string;
  pageId: string;
  responseData: any;
}

export function EditBlog({ data, blogId, responseData }: EditBlogProps) {
  const [editorData, setEditorData] = useState<string>(data.text_data.text);
  const [isSaving, setIsSaving] = useState(false);
  const editorRef = useRef<Editor>();

  const { fetchData: saveData } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${blogId}/update`,
    { method: 'POST' },
  );

  const handleEditorChange = (event: any) => {
    setEditorData(event);
  };

  const handleSaveClick = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const data = {
      output: {
        text_data: {
          text: editorData,
        },
      },
    };

    try {
      await saveData({
        body: JSON.stringify(data),
      });

      setIsSaving(false);
    } catch (err) {
      console.log(err);
    }
  };

  function TitleCard() {
    return (
      <>
        {/* <div className="text-3xl mb-7 w-full text-wrap font-bold">{data.title}</div> */}
        <div className="flex items-center">
          <h4 className="text-small font-semibold leading-none text-default-600">
            {responseData.user_info?.name}
          </h4>
          <div className="mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
          <h4 className="text-small font-semibold leading-none text-default-600">
            {dayjs(data.created_at).format('DD MMM YYYY')}
          </h4>
        </div>
      </>
    );
  }

  const openImageDialog = () => {
    if (editorRef.current) {
      editorRef.current.windowManager.open({
        title: 'Change Image URL',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'imageUrl',
              label: 'Image URL',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Cancel',
          },
          {
            type: 'submit',
            text: 'Change',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const data = api.getData();
          const selectedNode = editorRef.current?.selection.getNode();
          if (selectedNode?.nodeName === 'IMG') {
            (selectedNode as HTMLImageElement).src = data.imageUrl;
            (selectedNode as HTMLImageElement).setAttribute('data-mce-src', data.imageUrl);

            // Directly update the content in the editor
            const updatedContent = editorRef.current?.getContent({ format: 'raw' });
            setEditorData(updatedContent);
          }
          api.close();
        },
      });
    }
  };

  const openInsertImageDialog = () => {
    if (editorRef.current) {
      editorRef.current.windowManager.open({
        title: 'Insert Image',
        body: {
          type: 'panel',
          items: [
            {
              type: 'input',
              name: 'imageUrl',
              label: 'Image URL',
            },
          ],
        },
        buttons: [
          {
            type: 'cancel',
            text: 'Cancel',
          },
          {
            type: 'submit',
            text: 'Insert',
            primary: true,
          },
        ],
        onSubmit: (api) => {
          const data = api.getData();
          const { imageUrl } = data;
          editorRef.current?.execCommand('mceInsertContent', false, `<img src="${imageUrl}" data-mce-src="${imageUrl}" />`);

          // Directly update the content in the editor
          const updatedContent = editorRef.current?.getContent({ format: 'raw' });
          setEditorData(updatedContent);

          api.close();
        },
      });
    }
  };

  return (
    <div className="w-full p-8 sm:p-4">
      <div className="bg-white p-8 sm:p-4 rounded-2xl h-full">
        <div className="h-fit w-full flex flex-col justify-center items-center">
          <div className="max-w-[35rem] flex flex-col">
            <TitleCard />
            <Spacer y={4} />
            {/* {data.text_data.img_src && ( */}
            {/*  <div className="mb-6"> */}
            {/*    <img src={data.text_data.img_src} className="max-h-[400px] sm:max-h-[300px] m-auto" /> */}
            {/*  </div> */}
            {/* )} */}
            <div className="my-5">
              <Editor
                apiKey="1ygo2fyq13zubz5u0nm0b94vg7c5sn9r4jgsthkssx6z1l32" // You can get a free API key from TinyMCE
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={editorData}
                init={{
                  // height: 500,
                  menubar: false,
                  plugins: [
                    // "image charmap print anchor",
                    // "searchreplace visualblocks code fullscreen",
                    // "insertdatetime media table paste code",
                    'autoresize',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    removeformat | customChangeImage | insertImageButton',
                  content_style: 'body { overflow-x: hidden; } img, table { max-width: 100%; }', // Custom CSS to disable horizontal overflow
                  autoresize_bottom_margin: 20, // Optional: Add some bottom margin
                  autoresize_overflow_padding: 10, // Optional: Add padding
                  autoresize_min_height: 200, // Set a minimum height for the editor
                  autoresize_max_height: 800, // Set a maximum height for the editor
                  setup: (editor) => {
                    editor.ui.registry.addIcon('change-image', `
                      <svg fill="#000000" width="27px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="-120 0 700 455" stroke="#000000" strokeWidth="0.00455">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <path d="M0,0v455h455V0H0z M259.405,80c17.949,0,32.5,14.551,32.5,32.5s-14.551,32.5-32.5,32.5s-32.5-14.551-32.5-32.5S241.456,80,259.405,80z M375,375H80v-65.556l83.142-87.725l96.263,68.792l69.233-40.271L375,299.158V375z"></path>
                        </g>
                      </svg>
                    `);
                    editor.ui.registry.addButton('customChangeImage', {
                      icon: 'change-image',
                      tooltip: 'Change Image URL',
                      onAction: () => openImageDialog(),
                    });
                    editor.ui.registry.addButton('insertImageButton', {
                      icon: 'image',
                      tooltip: 'Insert Image',
                      onAction: () => openInsertImageDialog(),
                    });
                  },
                }}
                onEditorChange={handleEditorChange}
              />
            </div>
            <Button
              radius="full"
              size="md"
              style={{
                background: 'var(--BG-6, #E0E7FF)',
                color: 'var(--Primary-Color, #5046E5)',
              }}
              onClick={handleSaveClick}
              startContent={<Icon icon="material-symbols:check-circle-outline" style={{ fontSize: '1.5rem' }} />}
              isLoading={isSaving}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </div>

  );
}
