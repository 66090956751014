import { create } from 'zustand';

type TextChanges = {
    id: string;
    value: string | number;
};

type StoreState = {
    paused: boolean;
    textChanges: TextChanges | null;
    onTextChange: (id: string, value: string | number) => void;
    setPaused: (value: boolean) => void;
};

export const useStore = create<StoreState>((set) => ({
    paused: false,
    setPaused: (value: boolean) => set({ paused: value }),
    textChanges: null,
    onTextChange: (id: string, value: string | number) =>
        set({
            textChanges: { id, value },
        }),
}));
