import React, { useEffect, useState } from 'react';
import { prefetch } from 'remotion';
import { Spacer, Spinner } from '@nextui-org/react';

import RemotionPlayer from '../../remotion/RemotionPlayer';
import DemoTemplate3 from '../../remotion/demo/3';

const DATA = [
  'https://storage.googleapis.com/lica-video/test/demo-1.png',
  'https://storage.googleapis.com/lica-video/test/demo-2.png',
  'https://storage.googleapis.com/lica-video/test/demo-3.png',
  'https://storage.googleapis.com/lica-video/test/demo-2-1.mp3',
  'https://storage.googleapis.com/lica-video/test/demo-2-2.mp3',
  'https://storage.googleapis.com/lica-video/test/demo-2-3.mp3',
];
const VIDEO_CONFIG = DATA;

function Demo2() {
  const [isMediaCached, setIsMediaCached] = useState(false);

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */ }
      }
    }
    return links;
  }

  useEffect(() => {
    if (!isMediaCached) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(VIDEO_CONFIG);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [isMediaCached]);

  const isVideoReady = () => isMediaCached;

  return (
    <div className="p-4 h-full">
      {isVideoReady() ? (
        <div className="flex flex-col h-full items-center overflow-hidden">
          <div style={{ color: '#B6B6B6' }}>
            Events in SF
          </div>
          <Spacer y={4} />
          <div className="flex flex-col flex-auto items-center justify-between rounded-lg w-full">
            <div
              style={{ aspectRatio: 9 / 16 }}
              className="rounded-xl flex-auto"
            >
              <RemotionPlayer
                template={DemoTemplate3}
                data={{
                  fps: 30, duration: 23, width: 324, height: 576,
                }}
                clickToPlay={false}
              />
            </div>
            <Spacer y={4} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="default" />
        </div>
      )}
    </div>
  );
}

export default Demo2;
