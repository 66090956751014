import { Player } from '@remotion/player';
import AudioViz from './audiogram';

function AudioPlayer({ data, style }: { data: any, style?: any }) {
  const duration = Math.ceil(data.duration * 30);

  return (
    <Player
      style={{ height: '100%', width: '100%', ...style }}
      component={AudioViz}
      inputProps={data}
      durationInFrames={duration}
      fps={30}
      compositionWidth={324}
      compositionHeight={324}
      controls
      autoPlay={false}
    />
  );
}

export default AudioPlayer;
