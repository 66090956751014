import React from 'react';

interface VideoRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
  contentType: any;
  responseData: any;
}

export async function downloadContent(videoData: any) {
  const data = {
    composition: 'Template',
    inputProps: videoData,
  };
  let downloadLink = '';

  if (videoData.download_links) {
    downloadLink = videoData.download_links.media;
    try {
      const response = await fetch(downloadLink);

      if (!response.ok) {
        throw new Error(
          `Failed to fetch from download link: ${response.statusText}`,
        );
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const downloadAnchorTag = document.createElement('a');
      downloadAnchorTag.href = url;
      downloadAnchorTag.setAttribute('download', 'lica-video.mp4');
      document.body.appendChild(downloadAnchorTag);
      downloadAnchorTag.click();
      document.body.removeChild(downloadAnchorTag);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading from provided link:', error);
    }
  } else {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch from backend: ${response.statusText}`);
      }

      const result = await response.json();
      const downloadAnchorTag = document.createElement('a');
      downloadAnchorTag.href = result.outputFile;
      downloadAnchorTag.setAttribute('download', 'lica-video.mp4');
      document.body.appendChild(downloadAnchorTag);
      downloadAnchorTag.click();
      document.body.removeChild(downloadAnchorTag);
    } catch (err) {
      console.error('Error fetching video from backend:', err);
    }
  }
}

export async function downloadVideo(contentId: string, mp4URL?: string) {
  if (mp4URL) {
    const response = await fetch(mp4URL);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch from download link: ${response.statusText}`,
      );
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const downloadAnchorTag = document.createElement('a');
    downloadAnchorTag.href = url;
    downloadAnchorTag.setAttribute('download', 'lica-video.mp4');
    document.body.appendChild(downloadAnchorTag);
    downloadAnchorTag.click();
    document.body.removeChild(downloadAnchorTag);
    window.URL.revokeObjectURL(url);
  } else {
    const response = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/v1/download/${contentId}/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch from backend: ${response.statusText}`);
    }

    const result = await response.json();
    const downloadAnchorTag = document.createElement('a');
    downloadAnchorTag.href = result.outputFile;
    downloadAnchorTag.setAttribute('download', 'lica-video.mp4');
    document.body.appendChild(downloadAnchorTag);
    downloadAnchorTag.click();
    document.body.removeChild(downloadAnchorTag);
  }
}

export async function fetchContent(
  token: string,
  videoId: string,
  setContentRequest: (value: React.SetStateAction<VideoRequestState>) => void,
) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/v1/video-requests/${videoId}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error('Response not ok');
    }

    const responseData = await response.json();

    if (responseData.data.status === 'completed') {
      setContentRequest((prevState) => ({
        ...prevState,
        loading: false,
        loaded: true,
        contentType: responseData.data.content_type,
        data: responseData.data.output,
        responseData: responseData.data,
      }));
    } else if (responseData.data.status === 'failed') {
      setContentRequest((prevState) => ({
        ...prevState,
        loading: false,
        loaded: false,
        loadingFailed: true,
        contentType: null,
        data: null,
        responseData: null,
      }));
    } else {
      setTimeout(() => {
        fetchContent(token, videoId, setContentRequest);
      }, 10000);
    }
  } catch (error) {
    console.error(error);
  }
}
