import { ForwardedRef, forwardRef } from 'react';
import { Img } from 'remotion';

const Image = forwardRef((props: any, ref: ForwardedRef<any>) => (
  <div
    style={props.style}
    className="overflow-hidden"
		>
    <Img
      className={`w-full h-full object-cover ${props.className}`}
      ref={ref}
      src={props.src}
      onClick={props.onClick}
    />
  </div>
));

Image.displayName = 'Image';

export default Image;
