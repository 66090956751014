import React, { Key, useEffect } from 'react';

interface CarouselSlideProps {
  imageSrc: string;
  title: string;
  index?: Key;
  count?: number;
  setIndex?: any;
  imageStyle:any;
}

const CarouselSlide: React.FC<CarouselSlideProps> = ({
  imageSrc,
  title,
  index,
  setIndex,
  imageStyle,
}) => {
  useEffect(() => {
    setIndex();
  }, [index]);

  return (
    <div className="carousel-slide absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-start items-center">
      <img
        src={imageSrc}
        alt={title}
        style={imageStyle}
        className="max-w-full h-auto rounded-lg my-20"
      />
    </div>
  );
};

export default CarouselSlide;
