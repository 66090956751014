import React, {
  ForwardedRef, forwardRef, useEffect, useState,
} from 'react';
import { Player } from '@remotion/player';

import TagsAudioViz from './tags-audiogram';
import AudioViz from './minimal-audiogram';

interface Author {
  name: string;
  avatarUrl: string;
}
interface MetaData {
  author: Author;
  created: string;
  preview: string;
  collection: string;
}
interface AudioData {
  src: string;
  srt_file: string;
  title: string;
  transcript: string;
  duration: number;
  width: number;
  height: number;
  image: string;
  podcast_style?: string;
  meta: MetaData;
  author: Author;
}
interface Props {
  data: AudioData;
  style?: any;
  showTranscript?: boolean;
}

const PodcastPlayer = forwardRef((props: Props, ref: ForwardedRef<any>) => {
  const duration = Math.ceil(props.data.duration * 30);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });
  const { showTranscript } = props;
  const aspectRatio = showTranscript ? 16 / 9 : 1;
  const currentWidth = showTranscript ? 346 : 556;
  const currentHeight = showTranscript ? 346 : 556;
  useEffect(() => {
    const updateDimensions = () => {
      const windowHeight = window.innerHeight;

      // Minimum dimensions
      const minWidth = showTranscript ? 500 : 300;
      const minHeight = showTranscript ? 500 : 300;

      // Maximum dimensions
      const maxWidth = showTranscript ? 800 : 600;
      const maxHeight = showTranscript ? 800 : 600;

      let newWidth = Math.max(windowHeight - currentWidth, minWidth);
      let newHeight = newWidth * aspectRatio;

      // Check if the compositor height exceeds the window height or falls below minimum height
      if (newHeight > windowHeight - currentHeight || newHeight < minHeight) {
        newHeight = Math.min(Math.max(windowHeight - currentHeight, minHeight), newHeight);
        newWidth = newHeight / aspectRatio;
      }

      // Apply maximum constraints
      if (newWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth * aspectRatio;
      }
      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight / aspectRatio;
      }

      setDimensions({
        width: newWidth,
        height: newHeight,
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, [showTranscript, aspectRatio, setDimensions]);

  return (
    <Player
      ref={ref}
      component={props.data?.podcast_style === 'tags' ? TagsAudioViz : AudioViz}
      inputProps={{
			  ...props.data,
			  showTranscript: showTranscript as boolean,
			  width: dimensions.width,
			  height: dimensions.height,
      }}
      durationInFrames={duration}
      fps={30}
      compositionWidth={256}
      compositionHeight={showTranscript ? Math.floor(256 * aspectRatio) : 256}
      style={{
			  width: dimensions.width,
			  height: dimensions.height,
      }}
      autoPlay={false}
      controls
      allowFullscreen={false}
      showVolumeControls={false}
      clickToPlay={false}
    />
  );
});

PodcastPlayer.displayName = 'PodcastPlayer';

export default PodcastPlayer;
