import MobileDetect from 'mobile-detect';

export const isHyperlink = (text: string): boolean => {
  const regex = /(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?/gi;
  return regex.test(text);
};

export const CONTENT_TYPE_TO_TITLE: Record<string, string> = {
  VIDEO: 'Video',
  PODCAST: 'Podcast',
  BLOGPOST: 'Blog',
};

export const getMediaLinks = (data: any) => {
  let links: string[] = [];
  for (const key in data) {
    const val = data[key];

    if (val === null) continue;

    if (typeof val === 'object') {
      links = links.concat(getMediaLinks(val));
    } else if (Array.isArray(val)) {
      val.forEach((obj: any) => {
        links = links.concat(getMediaLinks(obj));
      });
    } else if (typeof val === 'string') {
      try {
        const url = new URL(val);
        const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
        if (isMedia) {
          links.push(val);
        }
      } catch (e) { /* empty */ }
    }
  }
  return links;
};

export const getMediaType = async (url: string | undefined) => {
  try {
    if (url) {
      const response = await fetch(url, { method: 'HEAD' });
      const contentType = response.headers.get('Content-Type');
      if (contentType) {
        if (contentType.includes('image')) {
          return 'IMAGE';
        } if (contentType.includes('video')) {
          return 'VIDEO';
        }
      }
    } else {
      return 'ERROR';
    }
  } catch (error) {
    console.log('Error fetching URL:', error);
    return 'ERROR';
  }
};
export const getCurrentDeviceType = () => {
  const md = new MobileDetect(navigator.userAgent);
  return md.mobile() ? 'phone' : 'desktop';
};

export const getCookie = (name: string): string => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return '';
};
