import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '@nextui-org/react';
import { prefetch } from 'remotion';

import RemotionPlayer from '../../remotion/RemotionPlayer';
import { TURING_INTERACTIVE_1 } from '../../remotion/utils/templates';

const GroupChild = styled.div`
    background-color: #f5f7fa;
    border-top: 1px solid #e9edf1;
    box-sizing: border-box;
    height: 56px;
`;
const AllRightsReserved = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    text-shadow: 1px 0 0 #e9edf1, 0 1px 0 #e9edf1, -1px 0 0 #e9edf1,
    0 -1px 0 #e9edf1;
`;
const RectangleParent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 56px;
    font-size: 14px;
    color: #626262;
`;
const LicaWorld = styled.div`
    letter-spacing: -0.02em;
    line-height: 94px;
    font-weight: 600;
`;
const Share03Icon = styled.img`
    width: 24px;
    position: relative;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
`;
const Button2 = styled.div`
    position: relative;
    font-weight: 600;
`;
const Button1 = styled.div`
    width: 17.86%;
    top: calc(50% - 25px);
    right: 5.42%;
    left: 79.72%;
    border-radius: 1000px;
    background-color: #5046e5;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 9px 28px;
    box-sizing: border-box;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
`;
const NavBar = styled.div`
    top: 0;
    left: calc(50% - 720px);
    text-align: center;
    color: #121212;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e9edf1;
`;
const NavContainer = styled.div`
    width: 1278px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
`;
const WebinarParent = styled.div`
    position: absolute;
    top: 117px;
    left: calc(50% - 639px);
    width: 1278px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 137px;
    font-size: 24px;
`;
const AutoGeneratedTitle = styled.div`
    width: 810px;
    position: relative;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 500;
    display: none;
`;
const GenerativeAiAnd = styled.div`
    align-self: stretch;
    position: relative;
    font-size: 35px;
    line-height: 48px;
    font-weight: 600;
    color: #000;
`;
const AutoGeneratedTitleParent = styled.div`
    width: 810px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
`;
const FrameParent = styled.div`
    position: absolute;
    width: 1272px;
    top: 320px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 56px;
    text-align: center;
    font-size: 18px;
    color: #5046e5;
`;
const HighlightsReels = styled.div`
    width: 810px;
    position: relative;
    line-height: 125%;
    font-weight: 600;
    display: inline-block;
`;
const GroupChild1 = styled.div`
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    border-radius: 100px;
    background-color: #e9edf1;
    width: 1176px;
    height: 2px;
`;
const GroupChild2 = styled.div`
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    border-radius: 100px;
    background-color: #5046e5;
    width: 72px;
    height: 4px;
`;
const RectangleContainer = styled.div`
    width: 1176px;
    position: relative;
    height: 4px;
`;
const HighlightsReelsParent = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
`;
const HighlightReelVideo = styled.video`
    width: 376px;
    position: relative;
    height: 654px;
`;
const HighlightReelVideoLandscape = styled.video`
    width: 576px;
    position: relative;
    height: 324px;
`;
const RectangleParent1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
`;
const GroupContainer = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
`;
const SpinnerContainer = styled.div`
    width: 1176px;
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
const FrameParent1 = styled.div`
    border-radius: 16px;
    background-color: #f5f7fa;
    border: 1px solid #e9edf1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 48px;
    gap: 36px;
`;
const FrameContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 48px;
`;
const RectangleParent2 = styled.div`
    width: 1176px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 36px;
`;
const FrameGroup = styled.div`
    position: absolute;
    top: 640px;
    left: calc(50% - 636px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
`;
const LightVersionUpdateRoot = styled.div`
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 3150px;
    overflow: hidden;
    text-align: left;
    font-size: 28px;
    color: #000;
    font-family: "Inter";
`;
const Subtitle = styled.div`
    font-size: 16px;
    text-align: left;
    color: rgb(51, 51, 51);
    font-style: italic;
`;

const Demo9: FunctionComponent = () => {
  const [reels, setReels] = useState(false);
  const [content, setContent] = useState(false);
  // const [isDownloading, setIsDownloading] = useState(false);
  const [isMediaCached, setIsMediaCached] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReels(true);
    }, 1000);
    setTimeout(() => {
      setContent(true);
    }, 1000);

    const promises: any[] = [];
    const mediaLinks = getMediaLinks(TURING_INTERACTIVE_1);
    mediaLinks.forEach((link) => {
      promises.push(prefetch(link).waitUntilDone());
    });
    Promise.allSettled(promises).then(() => {
      setIsMediaCached(true);
    });
  }, []);

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */ }
      }
    }
    return links;
  }

  // const handleDownloadClick = async () => {
  // 	if (isDownloading) {
  // 		return;
  // 	}
  //
  // 	setIsDownloading(true);
  //
  // 	fetch("https://storage.googleapis.com/lica-video/test/turing-podcast.mp4")
  // 		.then((resp) => resp.blob())
  // 		.then((blob) => {
  // 			const url = window.URL.createObjectURL(blob);
  // 			const a = document.createElement("a");
  // 			a.style.display = "none";
  // 			a.href = url;
  // 			a.download = "podcast";
  // 			document.body.appendChild(a);
  // 			a.click();
  // 			window.URL.revokeObjectURL(url);
  //
  // 			setIsDownloading(false);
  // 		});
  // };

  if (!isMediaCached) {
    return null;
  }

  return (
    <LightVersionUpdateRoot>
      <RectangleParent>
        <GroupChild />
        <AllRightsReserved>
          ©2024, All rights reserved by Lica World
        </AllRightsReserved>
      </RectangleParent>
      <NavBar>
        <NavContainer>
          <LicaWorld>Lica World</LicaWorld>
          <Button1>
            <Share03Icon alt="" src="/share-1.svg" />
            <Button2>Share this project</Button2>
          </Button1>
        </NavContainer>
      </NavBar>
      <WebinarParent>
        <img style={{ margin: 'auto', height: '200px' }} src="https://storage.googleapis.com/lica-video/turing_post_logo.png" />
      </WebinarParent>
      <FrameParent>
        <AutoGeneratedTitleParent>
          <AutoGeneratedTitle>Auto Generated Title</AutoGeneratedTitle>
          <GenerativeAiAnd>
            April 2
          </GenerativeAiAnd>
          <Subtitle>
            The coverage of Databricks' open-source DBRX model by WIRED marks a novel trend in AI, blending public relations with transparent technology unveiling. This shift towards treating AI innovations and their creators as celebrities reflects a broader change in the tech landscape, where AI developments are not only newsworthy but also have their pioneers celebrated and knighted, like Demis Hassabis of Google DeepMind. The trend extends beyond mere fame, with practical applications such as Belgian brewers using machine learning to refine beer flavors, indicating AI's deepening integration into various facets of life. The article suggests that this era of AI prominence could inspire future generations towards science and technology careers, highlighting an array of AI-related educational and entertainment resources for both families and investors.
          </Subtitle>
        </AutoGeneratedTitleParent>
      </FrameParent>
      <FrameGroup>
        <FrameContainer>
          <FrameParent1>
            <HighlightsReelsParent>
              <HighlightsReels>Newsletter Video Highlights</HighlightsReels>
              <RectangleContainer>
                <GroupChild1 />
                <GroupChild2 />
              </RectangleContainer>
            </HighlightsReelsParent>
            {reels ? (
              <GroupContainer>
                <HighlightReelVideoLandscape
                  src="https://storage.googleapis.com/lica-video/turing-47-higlights.mp4"
                  controls
                />
                <RectangleParent1>
                  <HighlightReelVideo
                    src="https://storage.googleapis.com/lica-video/turing-47-highlgihts-portrait.mp4"
                    controls
                  />
                </RectangleParent1>
              </GroupContainer>
            ) : (
              <SpinnerContainer>
                {/* <ProgressList */}
                {/*	items={[ */}
                {/*		"Loading highlights" */}
                {/*	]} */}
                {/*	onFinish={() => {}} */}
                {/* /> */}
                <Spinner label="Loading highlights..." size="lg" color="primary" />
              </SpinnerContainer>
            )}
          </FrameParent1>
          {/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
        </FrameContainer>
        <FrameContainer>
          <FrameParent1>
            <HighlightsReelsParent>
              <HighlightsReels>Usual suspects</HighlightsReels>
              <RectangleContainer>
                {/* <GroupChild1/> */}
                <GroupChild2 />
              </RectangleContainer>
            </HighlightsReelsParent>
            {reels ? (
              <GroupContainer>
                <HighlightReelVideoLandscape
                  src="https://storage.googleapis.com/lica-video/Usual-Suspects-47.mp4"
                  controls
                />
                {/* <RectangleParent1>
									<HighlightReelVideo
										src="https://storage.googleapis.com/lica-video/turing-Post-Highlights-portrait.mp4"
										controls
									/>
								</RectangleParent1> */}
              </GroupContainer>
            ) : (
              <SpinnerContainer>
                <Spinner label="Loading Video..." size="lg" color="primary" />
              </SpinnerContainer>
            )}
          </FrameParent1>
          {/* <Button4>
						<CheckCircleIcon1 alt="" src="/checkcircle.svg"/>
						<Button>Download this report</Button>
						<Share03Icon alt="" src="/download-icon.svg"/>
					</Button4> */}
        </FrameContainer>
        <FrameParent1>
          <HighlightsReelsParent>
            <HighlightsReels>Interactive Video</HighlightsReels>
            <RectangleContainer>
              <GroupChild1 />
              <GroupChild2 />
            </RectangleContainer>
          </HighlightsReelsParent>
          {content ? (
            <RectangleParent2>
              <GroupContainer style={{ justifyContent: 'center' }}>
                <div
                  style={{ width: '324px', aspectRatio: 9 / 16 }}
                  className="rounded-xl"
                >
                  <RemotionPlayer
                    data={TURING_INTERACTIVE_1}
                    clickToPlay={false}
                  />
                </div>
              </GroupContainer>
            </RectangleParent2>
          ) : (
            <SpinnerContainer>
              <Spinner label="Loading content..." size="lg" color="primary" />
            </SpinnerContainer>
          )}
        </FrameParent1>
        {/* <FrameParent1> */}
        {/*	<HighlightsReelsParent> */}
        {/*		<HighlightsReels>Podcast</HighlightsReels> */}
        {/*		<RectangleContainer> */}
        {/*			<GroupChild1/> */}
        {/*			<GroupChild2/> */}
        {/*		</RectangleContainer> */}
        {/*	</HighlightsReelsParent> */}
        {/*	{content ? ( */}
        {/*		<RectangleParent2> */}
        {/*			<GroupContainer style={{flexDirection: "column", justifyContent: "center", gap: "16px"}}> */}
        {/*				<div */}
        {/*					className="rounded-xl" */}
        {/*				> */}
        {/*					<AudioPlayer */}
        {/*						data={{ */}
        {/*							"duration": 107, */}
        {/*							"image": "https://storage.googleapis.com/lica-video/test/turing-logo.png", */}
        {/*							"src": "https://storage.googleapis.com/lica-video/test/podcast-1.mp3", */}
        {/*							"title": "TuringPost - March 18", */}
        {/*							"bg": "black", */}
        {/*						}} */}
        {/*					/> */}
        {/*				</div> */}
        {/*				<Button */}
        {/*					className="rounded-md text-base font-medium" */}
        {/*					startContent={<Icon icon="solar:download-minimalistic-bold"/>} */}
        {/*					style={{ */}
        {/*						color: "#ffffff", */}
        {/*						backgroundColor: "#5046e5", */}
        {/*					}} */}
        {/*					isLoading={isDownloading} */}
        {/*					onClick={handleDownloadClick} */}
        {/*				/> */}
        {/*			</GroupContainer> */}
        {/*		</RectangleParent2> */}
        {/*	) : ( */}
        {/*		<SpinnerContainer> */}
        {/*			<Spinner label="Loading content..." size="lg" color="primary"/> */}
        {/*		</SpinnerContainer> */}
        {/*	)} */}
        {/* </FrameParent1> */}
        {/* <FrameParent1>
					<HighlightsReelsParent>
						<HighlightsReels>Multimodal Machine Learning -
							Insights and Innovations</HighlightsReels>
						<RectangleContainer>
							<GroupChild1/>
							<GroupChild2/>
						</RectangleContainer>
					</HighlightsReelsParent>
					{content ? (
						<div style={{width: "1176px"}}>
							<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
								<img
									src="https://storage.googleapis.com/lica-video/test/bp-1.png"
									style={{width: "512px", height: "204px", objectFit: "cover"}}
								/>
								<div style={{fontWeight: 600, marginTop: "20px"}}>Bridging Human Sensory Experience with AI</div>
								<div style={{fontSize: "16px", marginTop: "8px"}}>The advent of multimodal machine learning stands as a
									testament to the
									progress we've made in the quest to imbue machines with a semblance of human sensory experience. By
									harmonizing disparate data streams — from the visual splendor captured in images to the intricate
									patterns found in textual data — this emerging field promises a revolution in how artificial
									intelligence perceives and interacts with the world around it. At the heart of this transformative
									journey is the pursuit to endow AI with a multidimensional understanding, akin to our own multifaceted
									sensory processing.
								</div>
							</div>
							<br/>
							<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
								<img
									style={{width: "512px", height: "204px", objectFit: "cover"}}
									src="https://storage.googleapis.com/lica-video/test/bp-2.png"
								/>
								<div style={{fontWeight: 600, marginTop: "20px"}}>The Evolution of AI Sensory Processing: From Single to
									Multiple Modalities
								</div>
								<div style={{fontSize: "16px", marginTop: "8px"}}>Our exploration begins with an understanding of how AI
									has evolved from processing single modalities to handling complex, multimodal inputs. Traditional
									models, designed with a focus on specific types of data, are making way for more versatile
									architectures capable of digesting a rich array of inputs — be it the visual context provided by
									images, the nuances carried by text, or the dynamics encapsulated in video data. This shift toward a
									holistic data integration strategy mirrors the human ability to synthesize information from sight,
									sound, and touch to form a coherent understanding of our environment.
								</div>
							</div>
							<br/>
							<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
								<img
									style={{width: "512px", height: "204px", objectFit: "cover"}}
									src="https://storage.googleapis.com/lica-video/test/bp-3.png"
								/>
								<div style={{fontWeight: 600, marginTop: "20px"}}>Innovations Leading the Charge: Perceiver Models and
									Beyond
								</div>
								<div style={{fontSize: "16px", marginTop: "8px"}}>Central to the advancements in multimodal learning are
									groundbreaking models like Perceiver and its successor, Perceiver IO. These models challenge the
									conventional norms by demonstrating an astonishing flexibility in handling diverse data types. By
									leveraging Transformer architecture, they process and integrate inputs across modalities, setting new
									benchmarks for AI's interpretative and generative capabilities. Whether it's generating vivid images
									from textual descriptions or classifying human actions in videos, these models encapsulate the essence
									of multimodal learning's potential to redefine the boundaries of machine intelligence.
								</div>
							</div>
							<br/>
							<div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
								<img
									style={{width: "512px", height: "204px", objectFit: "cover"}}
									src="https://storage.googleapis.com/lica-video/test/bp-4.png"
								/>
								<div style={{fontWeight: 600, marginTop: "20px"}}>Practical Applications: Bridging Theory and Reality
								</div>
								<div style={{fontSize: "16px", marginTop: "8px"}}>The practical implications of these innovations are
									profound, extending far beyond the theoretical. From enhancing content discovery on platforms like
									YouTube to revolutionizing social media interactions through automated caption generation for the
									visually impaired, the applications of multimodal machine learning are as diverse as they are
									impactful. Moreover, the advent of tools like DALL-E and CLIP heralds a new era in AI, where the
									creation and classification of content become more intuitive, opening up new avenues for creative
									expression and accessibility.
								</div>
							</div>
						</div>
					) : (
						<SpinnerContainer>
							<Spinner label="Generating summary..." size="lg" color="primary"/>
						</SpinnerContainer>
					)}
				</FrameParent1> */}
      </FrameGroup>
    </LightVersionUpdateRoot>
  );
};

export default Demo9;
