import { ForwardedRef, forwardRef, useEffect } from 'react';
import { Player, RenderPoster } from '@remotion/player';
import { loadFonts } from './fonts';
import { useStore } from '@/store/useStore';

import Template from './template';

const RemotionPlayer = forwardRef(
  (
    {
      data,
      template,
      clickToPlay = true,
    }: {
      data: any;
      template?: any;
      clickToPlay?: boolean;
    },
    ref: ForwardedRef<any>,
  ) => {
    let duration = 0;
    const { paused, setPaused } = useStore();

    if (template) {
      duration = data.duration * data.fps;
    } else {
      data.slides?.forEach((slide: any) => {
        duration += Math.ceil(slide.duration * data.fps);
      });
    }

    function findFontFamilies(obj: any): string[] {
      const fontFamilies = new Set<string>();

      function traverse(item: any) {
        if (typeof item !== 'object' || item === null) {
          return;
        }

        if (Object.prototype.hasOwnProperty.call(item, 'fontFamily')) {
          fontFamilies.add(item.fontFamily);
        }

        Object.keys(item).forEach((key) => {
          traverse(item[key]);
        });
      }

      traverse(obj);
      return Array.from(fontFamilies);
    }

    useEffect(() => {
      const fontFamilies = findFontFamilies(data);
      loadFonts(fontFamilies);
    }, [data]);

    useEffect(() => {
      const playerRef = ref as React.MutableRefObject<any>;
      if (!playerRef) return;
      if (paused) {
        playerRef.current.pause();
        setPaused(false);
      }
    }, [paused]);

    return (
      <Player
        ref={ref}
        style={{ width: '100%', borderRadius: '8px' }}
        component={template || Template}
        inputProps={data}
        durationInFrames={duration || 1}
        fps={data.fps}
        compositionWidth={data.width}
        compositionHeight={data.height}
        autoPlay={false}
        posterFillMode="player-size"
        clickToPlay={clickToPlay}
        controls
      />
    );
  },
);

RemotionPlayer.displayName = 'RemotionPlayer';

export default RemotionPlayer;
