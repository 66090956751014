import React, { useEffect, useState } from 'react';
import { prefetch } from 'remotion';
import { Spacer, Spinner } from '@nextui-org/react';

import AudioPlayer from '../../remotion/AudioPlayer';

const DATA = [
  'https://storage.googleapis.com/lica-video/eefdb7a8-348c-49c4-a1db-aa13162c1e66.jpeg',
  'https://storage.googleapis.com/lica-video/f919421a-a32e-4199-b0c1-894912d22f9e_audio.mp3',
];
const VIDEO_CONFIG = DATA;

function Demo4() {
  const [isMediaCached, setIsMediaCached] = useState(false);

  function getMediaLinks(data: any) {
    let links: string[] = [];
    for (const key in data) {
      const val = data[key];

      if (val === null) continue;

      if (typeof val === 'object') {
        links = links.concat(getMediaLinks(val));
      } else if (Array.isArray(val)) {
        val.forEach((obj: any) => {
          links = links.concat(getMediaLinks(obj));
        });
      } else if (typeof val === 'string') {
        try {
          const url = new URL(val);
          const isMedia = /\.(mp4|webm|jpg|jpeg|svg|png|webp|avif|gif|mp3)$/.test(url.pathname);
          if (isMedia) {
            links.push(val);
          }
        } catch (e) { /* empty */ }
      }
    }
    return links;
  }

  useEffect(() => {
    if (!isMediaCached) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(VIDEO_CONFIG);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [isMediaCached]);

  const isVideoReady = () => isMediaCached;

  return (
    <div className="p-4 h-full">
      {isVideoReady() ? (
        <div className="flex flex-col h-full items-center overflow-hidden">
          <div style={{ color: '#B6B6B6' }}>
            Adorable Companions
          </div>
          <Spacer y={4} />
          <div className="flex flex-col flex-auto items-center justify-between rounded-lg w-full">
            <div
              className="rounded-xl flex-auto"
            >
              <AudioPlayer
                data={{
								  duration: 9,
								  image: 'https://storage.googleapis.com/lica-video/eefdb7a8-348c-49c4-a1db-aa13162c1e66.jpeg',
								  src: 'https://storage.googleapis.com/lica-video/f919421a-a32e-4199-b0c1-894912d22f9e_audio.mp3',
								  title: 'Adorable Companions',
                }}
                style={{ backgroundColor: 'black' }}
              />
            </div>
            <Spacer y={4} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <Spinner color="default" />
        </div>
      )}
    </div>
  );
}

export default Demo4;
