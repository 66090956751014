import { Divider, Spacer } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { prefetch } from 'remotion';
import Video from './page/multi-content/video';
import Blog from './page/multi-content/blog';
import useFetch from '../../hooks/useFetch';
import { getMediaLinks } from '../../utils/common';
import LicaPage from '../../components/LicaPage';

interface ContentRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
}

function VideoHighlights() {
  const [contentRequest1, setContentRequest1] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [contentRequest2, setContentRequest2] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [contentRequest3, setContentRequest3] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [contentRequest4, setContentRequest4] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });
  const [contentRequest5, setContentRequest5] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
  });

  const { fetchData: getContent1 } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/DBRJ4xk4Gaqt38j8c7Mm/`,
    { method: 'GET' },
  );
  const { fetchData: getContent2 } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/jWUGtHkGWQIN3oUqMliJ/`,
    { method: 'GET' },
  );
  const { fetchData: getContent3 } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/WDVQkSgvdjDtnFahSjV7/`,
    { method: 'GET' },
  );
  const { fetchData: getContent4 } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/0aeDO6EPICjWN7Kuk1ju/`,
    { method: 'GET' },
  );
  const { fetchData: getContent5 } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/0neb5GzoNJyINeNlzV6g/`,
    { method: 'GET' },
  );

  const fetchContent1 = async () => {
    try {
      const responseData = await getContent1();

      if (responseData?.data?.status === 'completed') {
        setContentRequest1((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest1((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent1();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchContent2 = async () => {
    try {
      const responseData = await getContent2();

      if (responseData?.data?.status === 'completed') {
        setContentRequest2((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest2((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent2();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchContent3 = async () => {
    try {
      const responseData = await getContent3();

      if (responseData?.data?.status === 'completed') {
        setContentRequest3((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest3((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent3();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchContent4 = async () => {
    try {
      const responseData = await getContent4();

      if (responseData?.data?.status === 'completed') {
        setContentRequest4((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest4((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent4();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchContent5 = async () => {
    try {
      const responseData = await getContent5();

      if (responseData?.data?.status === 'completed') {
        setContentRequest5((prevState) => ({
          ...prevState,
          loading: false,
          loaded: true,
          data: responseData.data,
        }));
      } else if (responseData?.data?.status === 'failed') {
        setContentRequest5((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: null,
        }));
      } else {
        setTimeout(() => {
          fetchContent5();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (contentRequest1.loading && !contentRequest1.data && contentRequest2.loading && !contentRequest2.data && contentRequest3.loading && !contentRequest3.data && contentRequest3.loading && !contentRequest4.data && contentRequest5.loading && !contentRequest5.data) {
      fetchContent1().then(() => {
      });
      fetchContent2().then(() => {
      });
      fetchContent3().then(() => {
      });
      fetchContent4().then(() => {
      });
      fetchContent5().then(() => {
      });
    } else if (contentRequest1.data && contentRequest2.data && contentRequest3.data) {
      const promises: any[] = [];
      const mediaLinks = getMediaLinks(contentRequest1.data);
      mediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
    }
  }, [contentRequest1, contentRequest2, contentRequest3, contentRequest4, contentRequest5]);

  return (
    <LicaPage>

      <div className="h-full flex flex-col items-center min-h-[600px] bg-[#F5F7FA] p-6 rounded-lg">
        <div className="w-full text-xl font-semibold">Video Highlights</div>
        <Spacer y={2} />
        <Divider className="bg-[#E9EDF1] h-[2px]" />
        <Spacer y={5} />
        <div className="flex min-lg:h-[80vh] flex-col min-lg:flex-row w-full gap-20 min-lg:gap-5">
          <div className="flex flex-col items-center w-full min-h-96 h-[80vh] gap-5">
            {contentRequest1.data && (
            <Video
              customNumber={1}
              publicEdit
              contentId="DBRJ4xk4Gaqt38j8c7Mm"
              videoData={contentRequest1.data.output.video_data}
              responseData={contentRequest1.data}
            />
            )}
          </div>
          <div className="flex flex-col items-center w-full min-h-96 h-[80vh] gap-5">
            {contentRequest2.data && (
            <Video
              customNumber={2}
              publicEdit
              contentId="jWUGtHkGWQIN3oUqMliJ"
              videoData={contentRequest2.data.output.video_data}
              responseData={contentRequest2.data}
            />
            )}
          </div>
          <div className="flex flex-col items-center w-full min-h-96 h-[80vh] gap-5">
            {contentRequest3.data && (
            <Video
              customNumber={3}
              publicEdit
              contentId="WDVQkSgvdjDtnFahSjV7"
              videoData={contentRequest3.data.output.video_data}
              responseData={contentRequest3.data}
            />
            )}
          </div>
        </div>
        <div className="w-full text-2xl font-bold items-center justify-center text-center mt-20 mb-5">Blog Post</div>
        <Spacer y={2} />
        <Divider className="bg-[#E9EDF1] h-[2px]" />
        <Spacer y={5} />
        {contentRequest4.data && (
        <Blog
          publicEdit
          contentId="0aeDO6EPICjWN7Kuk1ju"
          data={contentRequest4.data.output}
          responseData={contentRequest4.data}
        />
        )}
        <div className="mt-10">
          {contentRequest5.data
            && (
            <>
              <div className="w-full text-xl font-semibold">Summary Points</div>
              <br />
              <Blog
                publicEdit
                contentId="0neb5GzoNJyINeNlzV6g"
                data={contentRequest5.data.output}
                responseData={contentRequest5.data}
              />
            </>
            )}
        </div>
      </div>
    </LicaPage>
  );
}

export default VideoHighlights;
