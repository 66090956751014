function VideoHighlights({ data }: { data: string[] }) {
  return (
    <div
      className="w-full overflow-scroll flex flex-col gap-8 items-center"
    >
      {data.map((video_url) => (
        <video key={video_url} controls>
          <source src={video_url} type="video/mp4" />
        </video>
      ))}
    </div>
  );
}

export default VideoHighlights;
