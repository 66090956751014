import { Fragment, RefObject } from 'react';
import { AbsoluteFill, Audio, Sequence } from 'remotion';

import { PlayerRef } from '@remotion/player';
import { linearTiming, TransitionSeries } from '@remotion/transitions';

import { Animated, Background, Video } from './comps';

function RemotionSlide(props: any) {
  const renderComponents = (components?: any[], style?: any) => (
    <div style={style}>
      {components?.map((component) => (
        <Sequence
          key={component.id}
          from={component.from}
          durationInFrames={component.durationInFrames}
          style={{ display: 'block', position: 'initial', height: 'auto' }}
        >
          <Animated
            component={component}
            slideDuration={props.duration}
          />
        </Sequence>
      ))}
    </div>
  );

  return (
    <>
      {props.style?.background ? <Background slideDuration={props.duration} {...props.style.background} /> : null}
      {props.audios
        ? props.audios.map((audio: any, index: number) => <Audio key={index} src={audio.src} volume={audio.volume} />)
        : null}
      {renderComponents(props.components, props.style)}
    </>
  );
}

function Template(props: {
  slides: any[];
  components?: any[];
  fps: number;
  width: number;
  height: number;
  duration?: number;
  audios?: any;
  video?: any;
  background?: any;
  slideTransition?: string;
}) {
  const getSlideTransition = (): any => undefined;

  const addSlideTransition = (index: number) => {
    if (!props.slideTransition || index === props.slides.length - 1) return null;

    return (
      <TransitionSeries.Transition
        key={index}
        presentation={getSlideTransition()}
        timing={linearTiming({ durationInFrames: 30 })}
      />
    );
  };

  return (
    <AbsoluteFill>
      {props.background ? <Background slideDuration={props.duration} {...props.background} /> : null}
      <TransitionSeries>
        {props.slides.map((slide, index) => (
          <Fragment key={`${slide.duration}-${index}`}>
            <TransitionSeries.Sequence
              durationInFrames={
                Math.ceil(slide.duration * props.fps) + (props.slideTransition ? 30 - 30 / props.slides.length : 0)
              }
            >
              <RemotionSlide {...slide} />
            </TransitionSeries.Sequence>
            {addSlideTransition(index)}
          </Fragment>
        ))}
      </TransitionSeries>
      {props.audios
        ? props.audios.map((audio: any, index: number) => (
          <Audio key={index} src={audio.src} volume={audio.volume} loop={audio.loop || false} />
        ))
        : null}
      {props.video ? <Video src={props.video.src} style={props.video.style} /> : null}
      {props.components?.map((component: any, index: number) => (
        <Sequence key={index} from={component.from} style={{ display: 'block', position: 'initial', height: 'auto' }}>
          <Animated
            component={component}
            slideDuration={props.duration}
          />
        </Sequence>
      ))}
    </AbsoluteFill>
  );
}

export default Template;
